export const environment = {
  production: false,
  staging: true,
  apiURL: 'https://control-api-dev-tauns.softwaredeva.com/',
  gqlEndpoint: 'graphql',
  reCaptchaKey: '6LfcW3saAAAAACJI-74HNsGxLOpaWWIk7OZpByzs',
  googleMaps: 'AIzaSyCgihKh5s8oEeXQ_un7JzZpUTKpEtV5xjo',
  operatorDomainPrefix: 'operator',
  superAdminHostName: 'control-dev-tauns.softwaredeva.com',
};
