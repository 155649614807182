<div class="authContainer">
    <div class="authCard">
        <div class="formContainer">
            <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
                <formly-form [form]="authForm" [fields]="authFields"></formly-form>
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (expire)="handleExpire()" (success)="handleSuccess()"
                    [useGlobalDomain]="false" size="normal" theme="light" type="image" formControlName="recaptcha">
                </ngx-recaptcha2>
                <button mat-raised-button type="submit" color="primary" class="loginBtn"
                    [disabled]="authForm.invalid || !validCaptcha">Entrar</button>
                <!-- <button mat-raised-button type="submit" color="primary" class="loginBtn"
                            [disabled]="authForm.invalid">Entrar</button> -->
                <a mat-button [routerLink]="['/auth/request-password']">Forgot password?</a>
            </form>
        </div>
    </div>
</div>