import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GoogleMapsApiService } from '../../google-maps-api/google-maps-api.service';

export const MX_LATLNG = { lat: 19.4322417, lng: -99.132692 };

export interface LocationAddress {
  street: string;
  number?: string;
  insideNumber?: string;
  neighborhood?: string;
  postalCode?: string;
  city?: string;
  lat?: number;
  lng?: number;
}

@Injectable()
export class GMPlacesService {
  private renderer: Renderer2;
  private placesService?: google.maps.places.PlacesService;
  private autocompleteService?: google.maps.places.AutocompleteService;
  private geocoder?: google.maps.Geocoder;
  private autocompleteSessionToken?: google.maps.places.AutocompleteSessionToken;
  
  apiLoaded: Observable<boolean>
  google?: any;
  places?: any;

  constructor(
    private googleMapsApiService: GoogleMapsApiService,
    private rendererFactory: RendererFactory2,
  ) {
    this.apiLoaded = this.googleMapsApiService.apiLoaded.pipe(
      map(loaded => {
        // console.log('GMPlacesService loaded', loaded);
        this.google = this.googleMapsApiService.google;
        this.places = this.google.maps.places;
        if (this.places) {
          this.initGooglePlacesService();
        }
        return !!this.places;
      })
    );
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private initGooglePlacesService() {
    // console.log('initGooglePlacesService');
    const div: HTMLDivElement = this.renderer.createElement('div');
    this.placesService = new this.places.PlacesService(div);
    this.autocompleteService = new this.places.AutocompleteService();
    this.geocoder = new this.google.maps.Geocoder();
    // console.log('initGooglePlacesService placesService', this.placesService);
    // console.log('initGooglePlacesService autocompleteService', this.autocompleteService);
    this.getAutocompleteSessionToken();
  }

  private getAutocompleteSessionToken() {
    this.autocompleteSessionToken = new this.places.AutocompleteSessionToken();
  }

  autocompleteAddress(query: string) {
    console.log('autocompleteAddress query', query);
    return new Observable((observer: Observer<google.maps.places.AutocompletePrediction[] | null>) => {
      if (!this.autocompleteService) {
        observer.next([]);
        observer.complete();
        return;
      }
      this.autocompleteService.getPlacePredictions({
        input: query,
        sessionToken: this.autocompleteSessionToken,
        origin: new google.maps.LatLng(MX_LATLNG.lat, MX_LATLNG.lng),
        types: ['address']
      }, results => {
        observer.next(results);
        observer.complete();
      });
    });
  }

  getPlaceDetails(placeId: string): Observable<LocationAddress> {
    console.log('getPlaceDetails placeId', placeId);
    return new Observable((observer: Observer<any>) => {
      if (!this.placesService) {
        observer.next({});
        observer.complete();
        return;
      }
      this.placesService.getDetails({
        placeId: placeId,
        sessionToken: this.autocompleteSessionToken
      }, (result: google.maps.places.PlaceResult | null) => {
        // console.log('getPlaceDetails result', result);
        this.getAutocompleteSessionToken();
        let parsed = null;
        if (result && result.address_components && result.geometry?.location) {
          parsed = this.parsePlaceObject(result.address_components, result.geometry.location);
        }
        observer.next(parsed);
        observer.complete();
      });
    });
  }

  private parsePlaceObject(address_components: google.maps.GeocoderAddressComponent[], location: google.maps.LatLng): LocationAddress {
    const address: LocationAddress = { street: '' };
    address_components.forEach(addComp => {
      if (addComp.types.includes('route')) {
        address.street = addComp.long_name;
      }
      if (addComp.types.includes('street_number')) {
        address.number = addComp.long_name;
      }
      if (addComp.types.includes('sublocality')) {
        address.neighborhood = addComp.long_name;
      }
      if (addComp.types.includes('postal_code')) {
        address.postalCode = addComp.long_name;
      }
      if (addComp.types.includes('locality')) {
        address.city = addComp.long_name;
      }
    });
    address.lat = location.lat();
    address.lng = location.lng();
    return address;
  }
}
