import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DynamicDialogService } from 'tauns-common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '../../services/auth.service';
import { AppConfig } from 'projects/tauns-control/src/app/app.config';

export function ValidateRePassword(compareControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      control.value !== undefined &&
      !!control.value &&
      control.value !== compareControl.value
    ) {
      return { rePassword: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  authForm = new UntypedFormGroup({});
  authFields: FormlyFieldConfig[] = [{
    validators: {
      validation: [
        { name: 'fieldMatch', options: { errorPath: 'passwordConfirm' } },
      ],
    },
    className: 'bold',
    fieldGroup: [
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          label: 'Password',
          placeholder: 'Mínimo 3 caracteres',
          required: true,
          minLength: 3,
        },
      },
      {
        key: 'passwordConfirm',
        type: 'input',
        templateOptions: {
          type: 'password',
          label: 'Confirm Password',
          placeholder: 'Re ingresa la contraseña',
          required: true,
        },
      },
    ],
  }];
  siteKey = AppConfig.reCaptchaKey;
  lang = 'es-419';
  validCaptcha = false;
  isOperator: boolean = false;

  token?: string;

  constructor(
    private dynamicDialogService: DynamicDialogService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.authService.logout().subscribe();
    this.authForm = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.isOperator = this.authService.validateIsOperator(this.router.url);
    const passwordFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]);
    this.authForm = new UntypedFormGroup({
      password: passwordFormControl,
      rePassword: new UntypedFormControl('', [Validators.required, ValidateRePassword(passwordFormControl)])
    });
    this.activatedRoute.queryParamMap.subscribe(queryParamMap => {
      this.token = queryParamMap.get('t') || undefined;
      console.log('token', this.token);
      if (!this.token) {
        // this.router.navigate(['/auth']);
        this.dynamicDialogService.showErrorDialog('Ocurrió un error, prueba haciendo "copy/paste" al link del correo.');
      }
    });
  }

  ngOnInit(): void {
  }

  handleSuccess() {
    this.validCaptcha = true;
  }

  handleExpire() {
    this.validCaptcha = false;
  }

  onSubmit() {
    let { password } = this.authForm.value;
    if (this.token) {
      this.dynamicDialogService.showLoadingDialog();
      this.authService.resetPassword({ token: this.token, password }, this.isOperator).subscribe(async res => {
        console.log(res);
        if (res) {
          await this.router.navigate(['/auth/login']);
        }
        this.dynamicDialogService.hideLoadingDialog();
        this.dynamicDialogService.showMessageDialog('Actualizada', 'Se actualizó correctamente la contraseña.');
      });
    }
  }

}
