import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { SubItemDialogComponent, SubItemDialogData } from './sub-item-dialog.component';

export interface SubItemDialogOptions {
  fields: FormlyFieldConfig[];
  options?: FormlyFormOptions;
  disabled?: boolean;
  readonly?: boolean;
}

@Injectable()
export class SubItemDialogService {

  constructor(
    private matDialog: MatDialog
  ) { }

  showSubItemDialog(options: SubItemDialogOptions, item?: any): MatDialogRef<SubItemDialogComponent> {
    return this.matDialog.open<SubItemDialogComponent, SubItemDialogData>(SubItemDialogComponent, {
      minWidth: '800px',
      disableClose: true,
      data: {
        ...options,
        item
      }
    });
  }
}
