import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogService } from 'tauns-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '../../services/auth.service';
import { AppConfig } from 'projects/tauns-control/src/app/app.config';

@Component({
  selector: 'app-login-super-admin',
  templateUrl: './login-super-admin.component.html',
  styleUrls: ['./login-super-admin.component.scss']
})
export class LoginSuperAdminComponent implements OnInit {
  authForm = new FormGroup<any>({});
  authFields: FormlyFieldConfig[] = [{
    key: 'email',
    type: 'input',
    templateOptions: {
      label: 'Correo',
      placeholder: 'Correo',
      required: true,
    }
  }, {
    key: 'password',
    type: 'input',
    templateOptions: {
      label: 'Contraseña',
      placeholder: 'Contraseña',
      required: true,
      type: 'password'
    }
  }];
  siteKey = AppConfig.reCaptchaKey;
  validCaptcha = false;

  constructor(
    private dynamicDialogService: DynamicDialogService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.authForm = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.authService.logoutSuperAdmin().subscribe();
  }

  openDialog() {
    this.dynamicDialogService.showLoadingDialog();
  }

  handleSuccess() {
    this.validCaptcha = true;
  }

  handleExpire() {
    this.validCaptcha = false;
  }

  onSubmit() {
    let { email, password } = this.authForm.value;
    this.dynamicDialogService.showLoadingDialog();
    this.authService.authLoginSuperAdmin({ email, password }).subscribe(async res => {
      console.log(res);
      if (res) {
        await this.router.navigate(['/super-admin']);
      }
      this.dynamicDialogService.hideLoadingDialog();
    });
  }

}
