import { environment } from '../environments/environment';

export class AppConfig {
  public static isProduction = environment.production;
  public static isStaging = environment.staging;
  public static apiURL: string = environment.apiURL;
  public static gqlEndpoint: string = environment.gqlEndpoint;
  public static reCaptchaKey: string = environment.reCaptchaKey;
  public static googleMaps: string = environment.googleMaps;
  public static operatorDomainPrefix: string = environment.operatorDomainPrefix;
  public static superAdminHostName: string = environment.superAdminHostName;
  constructor() {
  }
}
