import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(public authService: AuthService, public router: Router) { }
  canLoad(route: Route, segments: UrlSegment[]) {
    console.log('canLoad route', route)
    return this.authService.isAuthenticated.pipe(
      map(isAuthenticated => {
        console.log('canLoad route', route)
        console.log('canLoad segments', segments)
        const decoded = this.authService.getDecodedToken();
        console.log('canLoad getDecodedToken', decoded);
        if (!decoded) {
          this.router.navigate(['login']);
          return false;
        }
        if (route.path === 'admin') {
          if (decoded.type !== route.path) {
            this.router.navigate(['login']);
            return false;
          }
        }
        if (route.path === 'super-admin') {
          if (decoded.type !== route.path) {
            this.router.navigate(['login']);
            return false;
          }
        }
        if (!isAuthenticated) {
          this.router.navigate(['login']);
          return false;
        }
        return true;
      })
    );
  }
}
