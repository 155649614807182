import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GqlDebugInterceptor } from './interceptors/gql-debug.interceptor';
import { TimezoneOffsetInterceptor } from './interceptors/timezone-offset.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

const COMPONENTS: any[] = [
];

const DIRECTIVES: any[] = [
];

const PIPES: any[] = [
];
const SERVICES: any[] = [
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...SERVICES
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: GqlDebugInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimezoneOffsetInterceptor, multi: true },
        ...SERVICES
      ]
    };
  }
}
