import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogModule } from 'tauns-common';

import { FormlyModule } from '@ngx-formly/core';

import { AuthRoutingModule } from './auth-routing.module';

import { AuthComponent } from './auth/auth.component';
import { LoginSuperAdminComponent } from './views/login-super-admin/login-super-admin.component';
import { RequestPasswordComponent } from './views/request-password/request-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';

import { NgxCaptchaModule } from 'ngx-captcha';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AuthComponent,
    LoginSuperAdminComponent,
    RequestPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    MatButtonModule,
    DynamicDialogModule,
    NgxCaptchaModule,
    FormlyModule.forChild({}),
    FormlyMaterialModule,
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
      ]
    };
  }
}
