import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';

import { DynamicDialogModule } from 'tauns-common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppConfig } from './app.config';
import appValidators from './core/services/validators/appValidators';
import { GoogleMapsApiModule } from 'tauns-common';

registerLocaleData(localeEsMX, 'es-MX');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({...appValidators}),
    FormlyMaterialModule,
    DynamicDialogModule.forRoot({
      loadingLabel: 'Cargando...',
      errorTitle: 'Error'
    }),
    GraphQLModule,
    HttpClientModule,
    CoreModule.forRoot(),
    AuthModule.forRoot(),
    GoogleMapsApiModule.forRoot({
      apiKey: AppConfig.googleMaps,
      libraries: ['places']
    }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
