import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  authStarted?: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    // this.router.events.pipe(
    //   filter<any>((event: Event) => (event instanceof NavigationStart)),
    //   switchMap(event => {
    //     console.log('nav event', event);
    //     if (!this.authStarted) {
    //       this.authStarted = true;
    //       const isOperator = ;
    //       console.log('isOperator', isOperator);
    //       return this.authService.init(isOperator);
    //     } else {
    //       return of(this.authService.isAuthenticated);
    //     }
    //   })
    // ).subscribe(isAuth => {
    //   if (!isAuth) {
    //     // this.router.navigate(['/']);
    //   }
    // });
  }
  
  ngOnInit() {
    console.log('ngOnInit');
    const userType = this.authService.validateUserType();
    console.log('ngOnInit userType', userType);
    this.authService.init(userType).subscribe(isAuth => {
      console.log('AppComponent ngOnInit isAuth', isAuth);
    });
  }
  
}
