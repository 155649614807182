import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';

@Injectable()
export class GqlDebugInterceptor implements HttpInterceptor {
  gqlUrl: string;
  constructor() {
    this.gqlUrl = AppConfig.apiURL + AppConfig.gqlEndpoint;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log('intercept gql', request.url, this.gqlUrl);
    if (!AppConfig.isProduction && request.url === this.gqlUrl) {
      const body: any = request.body;
      if (body.query) {
        let query;
        query = body.query.split('\n');
        console.log('Gql query: ', query);
      }
      if (body.operationName) {
        console.log('Gql operationName: ', body.operationName);
      }
      if (body.variables) {
        console.log('Gql variables: ', body.variables);
      }
    }
    return next.handle(request);
  }
}
